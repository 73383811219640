import { defineStore } from "pinia";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";
import {
  Routes,
  HEADER_X_TOKEN,
  HEADER_TEAM_API_KEY,
  HEADER_ACCOUNT_API_KEY,
  apiServerUrl,
  HEADER_MEMBER_API_KEY,
  MemberExclusions,
} from "~/constants";
import { isAuthRoute } from "~/middleware/auth.global";

const state = {
	authInfo: null,
	currentTeam: null,
	authLoading: false,
	notification: {
		show: false,
		type: "info", // success/danger/info
		title: "",
		message: "",
	},
	mainPageLoadingValue: "Loading Data...",
	sampleDataOn: false,
	showGlobalSpinner: false,
	nav: false,
	showAlert: null,
	memberTeams: [],
	memberAvatarUrl: "",
	teamCategories: [],
	promoCode: "",
	showSearch: false,
	showVideoPlayer: false,
	selectedVideoDetails: {},
	searchTabSelected: 1,
};

export const useGlobalStore = defineStore("globalStore", {
	state: () => state,
	persist: {
		storage: persistedState.localStorage,
		afterRestore: (ctx) => {
			console.log("after restore", ctx.store.$state.nav);
			ctx.store.setResource({ resource: "nav", value: false });
		},
	},
	getters: {
		getNavStatus: (state) => state.nav,
		getShowAlert: (state) => state.showAlert,
		getMemberTeams: (state) => state.memberTeams,
	},
	actions: {
		setCurrentTeam(currentTeam) {
			if (currentTeam) {
				sessionStorage.setItem("currentTeam", JSON.stringify(currentTeam));
				this.currentTeam = currentTeam;
			} else {
				sessionStorage.removeItem("currentTeam");
			}
		},
		loadCurrentTeam() {
			const store = useGlobalStore();
			const currentTeam = sessionStorage.getItem("currentTeam");
			if (currentTeam) {
				this.currentTeam = JSON.parse(currentTeam);
			} else if (store.$state.authInfo?.team) {
				this.currentTeam = store.$state.authInfo?.team;
			}
		},
		setResource({ resource, value }) {
			this[resource] = value;
		},

		setItem({ item, id, resource }) {
			if (!this[resource]) {
				this[resource] = {};
			}
			this[resource][id] = item;
		},

		showNotification(options) {
			this["notification"] = { ...options, show: true };
		},

		hideNav() {
			this["nav"] = true;
		},

		showNav() {
			this["nav"] = false;
		},

		globalSpinnerStart() {
			this.setResource({
				resource: "mainPageLoadingValue",
				value: "Loading Data...",
			});
			this.setResource({ resource: "showGlobalSpinner", value: true });
		},

		globalSpinnerStop() {
			this.setResource({
				resource: "mainPageLoadingValue",
				value: "No Data",
			});
			this.setResource({ resource: "showGlobalSpinner", value: false });
		},

		refreshState() {
			const store = useGlobalStore();
			store.setResource({ resource: "authInfo", value: null });
			store.setResource({ resource: "authLoading", value: false });
			store.setResource({
				resource: "notification",
				value: {
					show: false,
					type: "info",
					title: "",
					message: "",
				},
			});
			store.setResource({ resource: "sampleDataOn", value: false });
			store.setResource({ resource: "showGlobalSpinner", value: false });
			store.setResource({ resource: "nav", value: false });
			store.setResource({ resource: "showAlert", value: null });
			store.setResource({ resource: "memberTeams", value: [] });
			store.setResource({ resource: "memberAvatarUrl", value: "" });
		},

		fetchAuthInfo({ token, timezone } = {}) {
			const store = useGlobalStore();
			const route = useRoute();
			return new Promise((resolve, reject) => {
				if (store.$state?.authInfo) {
					resolve(store.$state?.authInfo);
					return;
				}
				const resolvedTimezone =
					timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
				const promoCode = route.query.promoCode || store.$state.promoCode;
				const invitationId = route.query.invitation || "";

				useFetch(apiServerUrl() + "auth/info", {
					method: "GET",
					headers: {
						[HEADER_X_TOKEN]: token,
					},
					query: {
						timezone: resolvedTimezone,
						promoCode: promoCode,
						invitationId: invitationId,
						// teamId: store.$state?.currentTeam?.id,
					},
				})
					.then((res) => {
						const authInfo = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("error : ", error);
							resolve("auth/member-not-found");
						} else {
							store.setResource({
								resource: "memberAvatarUrl",
								value: authInfo?.member?.avatarUrl,
							});
							store.setResource({
								resource: "authInfo",
								value: authInfo,
							});
							this.loadCurrentTeam();
							resolve(authInfo);
						}
					})
					.catch((error) => {
						console.log("exception... : ", error);
						reject(error);
					});
			});
		},

		signInWithPopup() {
			const auth = getAuth();
			signInWithPopup(auth, new GoogleAuthProvider())
				.then((data) => {
					console.log(data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		sendPasswordResetEmail({ email }) {
			const auth = getAuth();
			return sendPasswordResetEmail(auth, email);
		},

		sendPasswordResetCustomEmail({ email }) {
			console.log("Sending Password Reset Email", email);
			return new Promise((resolve, reject) => {
				this.setResource({ resource: "authLoading", value: true });
				useFetch(`${apiServerUrl()}passwordreset`, {
					method: "POST",
					body: { email },
				})
					.then((res) => {
						const data = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(`reset password error : `, error);
							reject(error);
						} else {
							console.log(`reset password data : `, data);
							resolve(data);
						}
					})
					.catch((error) => {
						console.log("error -> ", error);
						reject(error);
					})
					.finally(() => {
						this.setResource({ resource: "authLoading", value: false });
					});
			});
		},

		signInWithGoogle() {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				store.setResource({ resource: "authLoading", value: true });
				const auth = getAuth();
				const provider = new GoogleAuthProvider();
				signInWithPopup(auth, provider)
					.then((data) => {
						console.log("google sign in succeeded : ", data);
						resolve(data);
					})
					.catch((err) => {
						console.error(err);
						reject(err);
					})
					.finally(() => {
						store.setResource({ resource: "authLoading", value: false });
						console.log("signInWithGoogle finally", store.$state);
					});
			});
		},

		signInWithEP(email, password) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				store.setResource({ resource: "authLoading", value: true });
				const auth = getAuth();
				signInWithEmailAndPassword(auth, email, password)
					.then((data) => {
						console.log("sign in with EP succeeded : ", data);
						resolve(data);
					})
					.catch((err) => {
						console.log("getting error  : ", err);
						console.log("eror code", err.code);
						if (err) {
							// possibility 1 -> old auth system user -> verify user -> migrate it (signup with current email and password)
							// possibility 2 -> typo in email
							useFetch(`${apiServerUrl()}oldauth/verify`, {
								method: "POST",
								body: {
									email,
									password,
								},
							})
								.then((res) => {
									const data = res.data.value;
									const error = res.error.value;
									if (error) {
										console.log(
											`old auth verification error : `,
											error
										);
										reject(err.code);
									} else {
										console.log(
											`old auth verification success : `,
											data
										);
										if (data.status) {
											createUserWithEmailAndPassword(
												auth,
												email,
												password
											)
												.then((data) => {
													console.log(
														"sign up with EP succeeded : ",
														data.user.uid
													);
													const uid = data.user.uid;
													useFetch(
														`${apiServerUrl()}oldauth/migrate`,
														{
															method: "PUT",
															body: {
																email: email,
																password: password,
																newUid: uid,
															},
														}
													)
														.then((data2) => {
															console.log("migrate data", data2);
															resolve(data2);
														})
														.catch((err2) => {
															console.log(
																"error migrate data",
																err2
															);
															reject(err.code);
														});
												})
												.catch((err) => {
													console.error("sign up failed", err);
													reject(err);
												});
										} else {
											reject(err.code);
										}
									}
								})
								.catch((error) => {
									console.log("error -> ", error);
									reject(error);
								});
						} else if (err.code === "auth/wrong-password") {
							reject(err.code);
						} else {
							console.log("e", err.code);
							reject(err);
						}
					})
					.finally(() => {
						store.setResource({ resource: "authLoading", value: false });
						console.log("signInWithEP finally", store.$state);
					});
			});
		},

		signInWithFirebaseCustomToken(token) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const auth = getAuth();
				signInWithCustomToken(auth, token)
					.then((data) => {
						console.log("sign in with custom token succeeded : ");
						resolve(data);
					})
					.catch((err) => {
						console.log("getting error  : ", err);
					});
			});
		},

		signUpWithEP(email, password) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				store.setResource({ resource: "authLoading", value: true });
				const auth = getAuth();
				createUserWithEmailAndPassword(auth, email, password)
					.then((data) => {
						console.log("sign up with EP succeeded : ", data);
						resolve(data);
					})
					.catch((err) => {
						console.error("sign up failed", err);
						reject(err);
					})
					.finally(() => {
						store.setResource({ resource: "authLoading", value: false });
						console.log("signUpWithEP finally", store.$state);
					});
			});
		},

		initUser() {
			const route = useRoute();
			const auth = getAuth();
			const store = useGlobalStore();
			onAuthStateChanged(auth, async (user) => {
				console.log("in auth action", user);

				if (store.authLoading) {
					console.log(store.authLoading);
					return;
				}

				if (user) {
					user.getIdToken(true).then((token) => {
						store
							.fetchAuthInfo({ token })
							.then(async (data) => {
								console.log("auth info", data);

								if (
									data.member.isVerified !== true &&
									user?.emailVerified === true
								) {
									store.verifiyMember();
								}

								if (isAuthRoute(route))
									return await navigateTo(Routes.Home);
							})
							.catch((error) => {
								console.error(error);
								store.signOut();
							});
					});
				}
			});
		},

		verifiyMember() {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
				const memberId = store.$state?.authInfo?.member?.id ?? -1;

				const requestOptions = {
					method: "PUT",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + "team/member/" + memberId + "/verify",
					requestOptions
				)
					.then((res) => {
						const val = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("member verification error : ", error);
							reject(error);
						} else {
							const newVal = {
								...store.$state.authInfo.member,
								isVerified: true,
							};
							store.setItem({
								resource: "authInfo",
								item: newVal,
								id: "member",
							});
							resolve(val);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		verifyMagicToken({ req }) {
			return new Promise((resolve, reject) => {
				const requestOptions = {
					method: "POST",
					body: req,
				};

				useFetch(
					apiServerUrl() + "oldauth/verify-magic-token",
					requestOptions
				)
					.then((res) => {
						if (res.error.value) {
							console.log("verify magic token failed");
							reject(error);
						} else {
							console.log("verify magic token succeeded");
							resolve(res.data.value);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		verifySendXAuthResetPasswordToken({ req }) {
			return new Promise((resolve, reject) => {
				const requestOptions = {
					method: "POST",
					body: req,
				};

				useFetch(
					apiServerUrl() + "oldauth/verify-reset-password-token",
					requestOptions
				)
					.then((res) => {
						if (res.error.value) {
							console.log("verify reset password token failed");
							reject(error);
						} else {
							console.log("verify reset password token succeeded");
							resolve(res.data.value);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		CreateFirebaseUserAndMigrate(email, password) {
			const auth = getAuth();
			return new Promise((resolve, reject) => {
				createUserWithEmailAndPassword(auth, email, password)
					.then((data) => {
						console.log("sign up with EP succeeded : ", data.user.uid);
						const uid = data.user.uid;
						useFetch(`${apiServerUrl()}oldauth/migrate`, {
							method: "PUT",
							body: {
								email: email,
								password: password,
								newUid: uid,
							},
						})
							.then((data2) => {
								console.log("migrate data", data2);
								resolve(data2);
							})
							.catch((err2) => {
								console.log("error migrate data", err2);
								reject(err2);
							});
					})
					.catch((err) => {
						console.error("sign up failed", err);
						reject(err);
					});
			});
		},

		adminAssumeAccountGetToken({ email, firebaseUID }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					params: {
						email: email,
						uid: firebaseUID,
					},
				};

				useFetch(apiServerUrl() + "account/assume", requestOptions)
					.then((res) => {
						if (res.error.value) {
							console.log("assume account failed");
							reject(res.error.value);
						} else {
							console.log("assume account succeeded");
							resolve(res.data.value);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		submitOnboardingData({ token, eaccount }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const requestOptions = {
					method: "PUT",
					headers: {
						[HEADER_X_TOKEN]: token,
						"Content-Type": "application/json", // Assuming the content type is JSON
					},
					body: JSON.stringify(eaccount), // Convert the eaccount object to a JSON string
				};

				useFetch(apiServerUrl() + "auth/onboarding/submit", requestOptions)
					.then((res) => {
						const newAuthInfo = res.data.value;
						store.setResource({
							resource: "authInfo",
							value: newAuthInfo,
						});
						this.setCurrentTeam(newAuthInfo.team);
						resolve(newAuthInfo);
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		async signOut() {
			return new Promise(async (resolve, reject) => {
				try {
					const auth = getAuth();
					const result = await auth.signOut();
					this.refreshState();
					localStorage.clear();
					sessionStorage.clear();
					resolve(result);
				} catch (error) {
					reject(error);
				}
			});
		},

		reauthenticate({ currentPassword }) {
			return new Promise((resolve, reject) => {
				const auth = getAuth();
				const cred = EmailAuthProvider.credential(
					auth.currentUser.email,
					currentPassword
				);
				reauthenticateWithCredential(auth.currentUser, cred)
					.then((res) => {
						resolve(res);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		changePassword({ newPassword }) {
			return new Promise((resolve, reject) => {
				const auth = getAuth();
				updatePassword(auth.currentUser, newPassword)
					.then(() => {
						resolve({ message: "Password updated successfully." });
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		checkIfContactExist({ email }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					params: {
						email: email,
					},
				};

				useFetch(apiServerUrl() + "team/contact/exists", requestOptions)
					.then((res) => {
						const exists = res.data;
						resolve(exists);
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchDragDropEmailTemplate(search, page) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + `team/template/email/dragdrop?search=${search}&page=${page}`,
					requestOptions
				)
					.then((res) => {
						const dragdropRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("dragdropRes error: ", error);
							reject(error);
						} else {
							resolve(dragdropRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchPlainTextEmailTemplate() {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + "team/template/email/plaintext",
					requestOptions
				)
					.then((res) => {
						const dragdropRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("dragdropRes error: ", error);
							reject(error);
						} else {
							resolve(dragdropRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchAllMemberTeams() {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(apiServerUrl() + "team/member/teams", requestOptions)
					.then((res) => {
						const memSubAccs = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("memSubAccs error: ", error);
							reject(error);
						} else {
							console.log("memSubAccs : ", memSubAccs);
							store.setResource({
								resource: "memberTeams",
								value: memSubAccs,
							});
							resolve(memSubAccs);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		createTeam({ name, timezone }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "POST",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: {
						name: name,
						timezone: timezone,
					},
				};

				useFetch(apiServerUrl() + "account/team/", requestOptions)
					.then((res) => {
						const createTeamRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("createTeamRes error: ", error);
							reject(error);
						} else {
							console.log("createTeamRes : ", createTeamRes);
							resolve(createTeamRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
		sendSlackNotification({ email, title, url }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "POST",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: JSON.stringify({
						email: email,
						video_title: title,
						video_url: url,
					}),
				};

				useFetch(
					apiServerUrl() + "account/team/slack-notification",
					requestOptions
				)
					.then((res) => {
						const resp = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("createTeamRes error: ", error);
							reject(error);
						} else {
							console.log("createTeamRes : ", resp);
							resolve(resp);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		updateDefaultTimezone({ tid, timezone }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "PUT",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: {
						timezone: timezone,
					},
				};

				useFetch(apiServerUrl() + "account/team/" + tid, requestOptions)
					.then((res) => {
						const updateTeamRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("updateTeamRes error: ", error);
							reject(error);
						} else {
							const newVal = {
								...store.$state.currentTeam,
								defaultTimeZone: timezone,
							};
							store.setItem({
								resource: "authInfo",
								item: newVal,
								id: "team",
							});
							this.setCurrentTeam(newVal);
							resolve(updateTeamRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		switchTeam({ sid }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + "account/team/" + sid + "/switch",
					requestOptions
				)
					.then((res) => {
						const switchAccountRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("switchAccountRes error: ", error);
							reject(error);
						} else {
							store.setResource({
								resource: "authInfo",
								value: switchAccountRes,
							});
							this.setCurrentTeam(switchAccountRes.team);
							resolve(switchAccountRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchTeamById({ tid }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(apiServerUrl() + "account/team/" + tid, requestOptions)
					.then((res) => {
						const getRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("getRes error: ", error);
							reject(error);
						} else {
							resolve(getRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		deleteTeam({ sid }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "DELETE",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(apiServerUrl() + "account/team/" + sid, requestOptions)
					.then((res) => {
						const deleteRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("deleteRes error: ", error);
							reject(error);
						} else {
							resolve(deleteRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchCursorDropdownOptions({ search, pageInfo, entity }) {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";
				let xsearch = search ?? "";

				useFetch(apiServerUrl() + "team/" + entity + "/dropdown", {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					params: {
						pageInfo: pageInfo,
						search: xsearch,
					},
				})
					.then((res) => {
						const dropdownRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("dropdownRes error: ", error);
							reject(error);
						} else {
							resolve(dropdownRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchDropdownOptions({ search, page, entity }) {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";
				let xsearch = search ?? "";

				useFetch(apiServerUrl() + "team/" + entity + "/dropdown", {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					params: {
						page: page,
						search: xsearch,
					},
				})
					.then((res) => {
						const dropdownRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("dropdownRes error: ", error);
							reject(error);
						} else {
							resolve(dropdownRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		createDropDownEntity({ entity, body }) {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";

				useFetch(apiServerUrl() + "team/" + entity + "/", {
					method: "POST",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: body,
				})
					.then((res) => {
						const dropdownRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("dropdownRes error: ", error);
							reject(error);
						} else {
							resolve(dropdownRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		checkVisibilitity({ entity }) {
			const store = useGlobalStore();
			const memRole = store.$state?.authInfo?.memberRole ?? -1;
			const exclusions = MemberExclusions[memRole];
			return !exclusions?.includes(entity);
		},

		resendVerificationEmail() {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "POST",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + "team/member/verify/sendmail",
					requestOptions
				)
					.then((res) => {
						const error = res.error.value;
						if (error) {
							console.log("verifcation mail send error: ", error);
							reject(error);
						} else {
							console.log("verifcation mail send success");
							resolve("Success");
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		downloadFile({ filename }) {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";

				useFetch(apiServerUrl() + "team/file/" + filename, {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				})
					.then((res) => {
						const dropdownRes = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("download error: ", res);
							reject(error);
						} else {
							console.log("download data", dropdownRes);
							resolve(dropdownRes);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		ablyAuthInfoUpdate({ data }) {
			const store = useGlobalStore();
			store.setResource({ resource: "authInfo", value: data });
		},

		ablyAccountUpdate({ data }) {
			const store = useGlobalStore();
			store.setItem({ resource: "authInfo", item: data, id: "account" });
		},

		ablyTeamUpdate({ data }) {
			const store = useGlobalStore();
			store.setItem({ resource: "authInfo", item: data, id: "team" });
		},

		ablyMemberUpdate({ data }) {
			const store = useGlobalStore();
			store.setItem({ resource: "authInfo", item: data, id: "member" });
		},

		ablyMemberRoleUpdate({ data }) {
			const store = useGlobalStore();
			store.setItem({ resource: "authInfo", item: data, id: "memberRole" });
		},

		saveRowTemplate(content, name) {
			console.log("store row passed =>", content);
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
				useFetch(`${apiServerUrl()}team/rowtemplate/create`, {
					method: "POST",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: {
						content: JSON.stringify(content),
						name,
					},
				})
					.then((res) => {
						const { status, data, error } = res;
						if (status.value === "error") {
							reject(error.value);
						} else {
							resolve(res.data.value);
						}
					})
					.catch((error) => {
						console.log("error -> ", error);
						reject(error);
					});
			});
		},

		deleteRowTemplate(name) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
				useFetch(`${apiServerUrl()}team/rowtemplate`, {
					method: "DELETE",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					body: {
						name,
					},
				})
					.then((res) => {
						const error = res.error.value;
						if (error) {
							console.log(
								"Failed to delete row template with error =>",
								error
							);
							reject(error);
						} else {
							console.log("Row template deleted with message => ", res);
							resolve(res.data.value);
						}
					})
					.catch((error) => {
						console.log("error -> ", error);
						reject(error);
					});
			});
		},

		fetchRowTemplate({ category }) {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";

				useFetch(apiServerUrl() + "team/rowtemplate", {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					query: {
						category,
					},
				})
					.then((res) => {
						let rowTemplates = res.data.value?.row_templates;
						const error = res.error.value;
						if (error) {
							console.log("error while fetching row templates: ", res);
							reject(error);
						} else {
							rowTemplates = rowTemplates.map((rt) => {
								return {
									metadata: {
										name: rt.name,
										category: "Headers",
									},
									...JSON.parse(rt?.content),
								};
							});
							resolve(rowTemplates);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchAllTeamCategories() {
			return new Promise((resolve, reject) => {
				const globalStore = useGlobalStore();
				const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey =
					globalStore.$state?.authInfo?.member?.apiKey ?? "";

				useFetch(apiServerUrl() + "team/category", {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				})
					.then((res) => {
						let categories = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log("error while fetching row templates: ", res);
							reject(error);
						} else {
							this.setResource({
								resource: "teamCategories",
								value: categories,
							});
							console.log("updating store => ", state.teamCategories);
							resolve(categories);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchBeeCatalogTemplates(search, page, template_type, category) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					query: { search, page, template_type, category },
				};

				useFetch(
					apiServerUrl() + "team/bee/catalog/templates",
					requestOptions
				)
					.then((res) => {
						const beeTemplates = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(
								"error while fetching bee catalog templates, error: ",
								error
							);
							reject(error);
						} else {
							console.log("fetch bee templates : ", beeTemplates);
							// store.setResource({ resource: "memberTeams", value: memSubAccs });
							resolve(beeTemplates);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchCompleteBeeCatalogTemplateById(id) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
				};

				useFetch(
					apiServerUrl() + "team/bee/catalog/templates/" + id,
					requestOptions
				)
					.then((res) => {
						const beeTemplate = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(
								"error while fetching bee catalog templates, error: ",
								error
							);
							reject(error);
						} else {
							console.log("fetch bee template : ", beeTemplate);
							beeTemplate["json_data"] = JSON.stringify(
								beeTemplate["json_data"]
							);
							// store.setResource({ resource: "memberTeams", value: memSubAccs });
							resolve(beeTemplate);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchBeeCatalogCategories(search, page) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_TEAM_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					query: { search, page },
				};

				useFetch(
					apiServerUrl() + "team/bee/catalog/categories",
					requestOptions
				)
					.then((res) => {
						const catalogCategories = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(
								"error while fetching bee catalog templates, error: ",
								error
							);
							reject(error);
						} else {
							console.log(
								"fetched bee categories : ",
								catalogCategories
							);
							resolve(catalogCategories);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		generateSubjectLines({ keywords }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.currentTeam?.apiKey ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "POST",
					// headers: {
					//   [HEADER_TEAM_API_KEY]: apiKey,
					//   [HEADER_MEMBER_API_KEY]: memberApiKey,
					// },
					body: {
						subjectLineKeywords: keywords,
					},
				};

				useFetch(apiServerUrl() + "suggestion/subject", requestOptions)
					.then((res) => {
						const error = res.error.value;
						if (error) {
							console.log(
								"error while generating subject lines, error: ",
								error
							);
							reject(error);
						} else {
							const subjectLines = res.data.value?.subjectLines;
							console.log("generated subject lines: ", subjectLines);
							resolve(subjectLines);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchTeamRevenueStats({ integrationType, from, to }) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const teamId = store.$state?.currentTeam?.id ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					query: {
						integrationType: integrationType,
						from: from,
						to: to,
					},
				};

				useFetch(
					`${apiServerUrl()}account/team/${teamId}/revenue`,
					requestOptions
				)
					.then((res) => {
						const revenueData = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(
								"error while fetching team revenue, error: ",
								error
							);
							reject(error);
						} else {
							resolve(revenueData);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},

		fetchTeamNewsletterRevenueStats({
			integrationType,
			offset,
			limit,
			search,
		}) {
			const store = useGlobalStore();
			return new Promise((resolve, reject) => {
				const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
				const teamId = store.$state?.currentTeam?.id ?? "";
				const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";

				const requestOptions = {
					method: "GET",
					headers: {
						[HEADER_ACCOUNT_API_KEY]: apiKey,
						[HEADER_MEMBER_API_KEY]: memberApiKey,
					},
					query: {
						integrationType: integrationType,
						offset: offset,
						limit: limit,
					},
				};

				useFetch(
					`${apiServerUrl()}account/team/${teamId}/newsletter/revenue`,
					requestOptions
				)
					.then((res) => {
						const revenueData = res.data.value;
						const error = res.error.value;
						if (error) {
							console.log(
								"error while fetching team newsletter revenue, error: ",
								error
							);
							reject(error);
						} else {
							resolve(revenueData);
						}
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
	},
});
